<template>
  <cds-main class="h-full list-io flex flex-col">
    <div
      class="pl-[16px] pr-[20px] py-[18px] flex justify-between items-center gap-20 bg-ems-gray900"
    >
      <div
        class="flex flex-wrap justify-start items-center gap-[20px] max-w-510px 2xl:max-w-1020px"
      >
        <div>
          <input-search
              class="ant-input-sm"
              v-model:value="formState.name"
              size="small"
              :placeholder="$t('common.search')"
              :has-search-advanced="false"
              :maxlength="50"
              @press-enter="onSearch"
              @change="onChangeInput"
          />
        </div>
        <div class="w-240px">
          <SelectSearchList
            ref="type"
            :options="OBJECT_TYPE_TEXT"
            v-model:value="formState.type"
            :allow-clear="true"
            :placeholder="$t('identity_object.form_label.type')"
            @change="onSearch"
          />
        </div>
        <div class="w-240px">
          <SelectSearchList
            ref="type"
            :multiple="true"
            :options="listGroupByUser"
            :label-prop="'name'"
            :value-prop="'id'"
            :is-label-i18n="false"
            v-model:value="formState.groupId"
            :allow-clear="true"
            :max-tag-count="1"
            :max-tag-text-length="10"
            :placeholder="$t('identity_object.form_label.group')"
            @change="onSearch"
          />
        </div>
        <div class="w-240px h-39px relative">
          <UploadSearchList
            v-model:value="formState.imgUrl"
            @change="onSearch"
          ></UploadSearchList>
        </div>
      </div>
      <div class="flex justify-end items-center gap-x-2">
        <!--              <router-link-->
        <!--                  v-if="checkHasPermission(permissions, 'object:create')"-->
        <!--                  to="/identity/object/create"-->
        <!--              >-->
        <!--                <cds-button type="info" size="small">-->
        <!--                  {{ $t('common.new') }}-->
        <!--                </cds-button>-->
        <!--              </router-link>-->
        <!-- <clip-button
          type="reset"
          class="flex items-center justify-center gap-1 mr-[16px] whitespace-nowrap"
          @click="refresh"
        >
          <cds-feather-icons
            type="rotate-cw"
            size="16"
            class="!text-ems-white mr-2"
          />
          {{ $t('common.refresh') }}
        </clip-button> -->
        <!-- <router-link to="/io-management/object-management/create">
          <clip-button
            type="primary"
            class="flex items-center justify-center gap-1 px-[26px]"
          >
            <cds-feather-icons
              type="plus"
              size="18"
              class="!text-ems-white mr-2"
            />
            {{ $t('identity_object.table.add') }}
          </clip-button>
        </router-link> -->
        <cds-search-form-button
          :buttons="buttonsForm"
          @onCreate="onCreate"
          @onClear="refresh"
          class="!pb-0"
        />
<!--        <a-upload name="file" :multiple="false" class="h-[40px]">-->
<!--          <clip-button-->
<!--            type="primary"-->
<!--            class="flex items-center justify-center gap-1 ml-[8px]"-->
<!--          >-->
<!--            <cds-feather-icons-->
<!--              type="arrow-up"-->
<!--              size="18"-->
<!--              class="!text-ems-white mr-2"-->
<!--            />-->
<!--            {{ $t('identity_object.table.upload') }}-->
<!--          </clip-button>-->
<!--        </a-upload>-->
      </div>
    </div>
    <cds-cards title="" class="h-full full-height-card m-0">
      <cds-table
        ref="table"
        has-checkbox
        :index-column="false"
        pagination
        :columns="columns"
        :api-fetch="apiFetch"
        :param-fetch="formState"
        :show-sorter-tooltip="true"
        :page-number="page"
        v-if="showTb"
        @changePage="changePage"
      >
        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'avatar' && record">
            <div
              class="flex items-center justify-center cursor-pointer"
              @click="onEdit(record)"
            >
              <div class="w-[62px] h-[62px] img-custom">
                <img
                  class="w-full h-full object-cover"
                  :src="
                    record.imagesAvatar
                      ? record.imagesAvatar
                      : require('@/static/img/no-img.png')
                  "
                  :alt="record.name"
                />
              </div>
            </div>
          </template>
          <template v-if="column.key === 'name' && record">
            <div
              class="flex items-center justify-start cursor-pointer"
              @click="onEdit(record)"
            >
              <p class="text-ems-main4 hover:underline">
                {{ record.name ? record.name : record.id }}
              </p>
            </div>
          </template>
          <template v-if="column.key === 'cccd' && record">
            <span v-if="record.type === OBJECT_TYPE.HUMAN">
              {{ record.exhibitDetail }}
            </span>
            <span v-else-if="record.type === OBJECT_TYPE.VEHICLE">
              {{ record.ownerIdCard }}
            </span>
          </template>
          <template v-if="column.key === 'ioGroups' && record">
            <div class="flex items-center gap-y-2" v-if="record.ioGroups">
              <div
                class="tag flex-wrap flex-shrink-0"
                v-for="(item, index) in record.ioGroups"
                :key="index"
              >
                <span
                  class="inline-block rounded-[4px] py-6px px-10px text-center mr-2 cursor-pointer text-ems-gray100"
                  v-if="index < 1"
                  @click="viewDetailGroup(item)"
                  :class="getColor(listGroupDetail[item].warningLevel)"
                >
                  {{
                    listGroupDetail[item] && listGroupDetail[item].name > 20
                      ? `${listGroupDetail[item].name.substring(0, 20)}...`
                      : listGroupDetail[item].name
                  }}
                </span>
              </div>
              <a-popover
                placement="top"
                overlay-class-name="popup-groups"
                v-if="record.ioGroups.length > 1"
              >
                <template #content>
                  <div>
                    <span
                      class="tag-custom inline-block rounded-[4px] py-[6px] text-ems-gray100 text-sm px-3 bg-ems-gray600 uppercase mr-1 mb-2 cursor-pointer"
                      v-for="(item, idx) in record.ioGroups"
                      :key="idx"
                      @click="viewDetailGroup(item)"
                      :class="getColor(listGroupDetail[item].warningLevel)"
                    >
                      {{
                        listGroupDetail[item] && listGroupDetail[item].name > 20
                          ? `${listGroupDetail[item].name.substring(0, 20)}...`
                          : listGroupDetail[item].name
                      }}
                    </span>
                  </div>
                </template>
                <span
                  class="inline-block rounded-[4px] p-1 px-2 bg-ems-gray600"
                >
                  ...
                </span>
              </a-popover>
            </div>
          </template>
          <template v-if="column.key === 'action' && record">
            <ActionButton
              :buttons="buttonActions"
              @onEdit="onEdit(record)"
              @onView="onView(record)"
              @onDelete="onDelete(record)"
            />
          </template>
        </template>
      </cds-table>
    </cds-cards>
  </cds-main>

  <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
</template>
<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import ConstantAPI from '@/config/ConstantAPI';
import { useStore } from 'vuex';
// import ClipButton from '@/components/buttons/ClipButton.vue';
import InputSearch from '@/components/input-search/index.vue';
import ActionButton from '@/components/action-button/index.vue';
import {
  defaultButton,
  OBJECT_TYPE_TEXT,
  OBJECT_TYPE,
} from '@/util/common-constant';
import { useI18n } from 'vue-i18n';
import { getText } from '@/util/common-utils';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { useRoute, useRouter } from 'vue-router';
import { FORM_MODE } from '../../util/common-constant';
import { debounce } from 'lodash';
import { formatDate, trim, hasPermEdit } from '../../util/common-utils';
import SelectSearchList from '@/components/select-search-list/SelectSearchList.vue';
import UploadSearchList from '../../components/upload-search-list/UploadSearchList.vue';
import { WARNING_LEVEL } from '@/config/Constant';

const apiFetch = ConstantAPI.OBJECT_MANAGEMENT.GET_BY_CONDITION;
const { buttonActions } = defaultButton('object');
const buttonsForm = [
  { type: 'RESET' },
  { type: 'CREATE', permission: 'object:create' },
];
const { t } = useI18n();
const table = ref(null);
const changePage = (page) => {
  router.push({
    query: {
      ...route.query,
      page: page,
    },
  });
};
const getColor = computed(() => (lv) => {
  let color = WARNING_LEVEL[lv - 1].color;
  return color;
});
const columns = ref([
  {
    title: t('object_information.image_title'),
    dataIndex: 'avatar',
    key: 'avatar',
    resizable: true,
    width: 120,
    align: 'center',
  },
  {
    title: t('object_information.object_name'),
    dataIndex: 'name',
    key: 'name',
    resizable: true,
    width: 200,
  },
  {
    title: t('object_information.object_type'),
    dataIndex: 'type',
    resizable: true,
    key: 'type',
    width: 200,
    customRender({ record }) {
      return t(getText(record.type, OBJECT_TYPE_TEXT));
    },
  },
  {
    title: t('object_information.phone_number'),
    dataIndex: 'phone',
    key: 'phone',
    width: 200,
  },
  {
    title: t('object_information.cccd'),
    key: 'cccd',
    width: 200,
  },
  {
    title: t('object_information.tax_code'),
    dataIndex: 'taxCode',
    key: 'taxCode',
    width: 200,
  },
  {
    title: t('object_information.insurance_code'),
    dataIndex: 'insuranceCode',
    key: 'insuranceCode',
    width: 300,
  },
  {
    title: t('object_information.group_title'),
    dataIndex: 'ioGroups',
    resizable: true,
    key: 'ioGroups',
    width: 200,
  },
  {
    title: t('object_information.created_date'),
    dataIndex: 'createdTime',
    key: 'createdTime',
    width: 200,
    customRender({ record }) {
      return formatDate(record.createdTime);
    },
  },
  {
    title: t('common.action'),
    key: 'action',
    width: 150,
    fixed: 'right',
    align: 'center',
  },
]);
const { state, dispatch } = useStore();
const perms = computed(() => state.auth.userInfo.permissions);
const page = ref(1);
const route = useRoute();
const showTb = ref(false);
const listGroupByUser = computed(() => state.ioGroup.listGroupByUser);
const listGroupDetail = computed(() => {
  let groups = {};
  state.ioGroup.listGroup.map((el) => {
    groups[el.id] = el;
  });
  return groups;
});
onMounted(async () => {
  dispatch(
    'setTitleHeader',
    t('object_information.object_information_management')
  );
  if (route.query.page) {
    page.value = parseInt(route.query.page);
  }
  formState.name = route.query.searchName ? route.query.searchName : null;
  formState.type = route.query.searchType
    ? Number(route.query.searchType)
    : null;
  formState.groupId = [];
  if (route.query.searchGroupId) {
    let arr = route.query.searchGroupId.split(',');
    arr.forEach((e) => {
      formState.groupId.push(Number(e));
    });
  }
  formState.imgUrl = route.query.searchImage ? route.query.searchImage : '';
  await dispatch('ioGroup/getAllGroup');
  await dispatch('ioGroup/getAllGroupByUser');
  showTb.value = true;
});
let formState = reactive({
  name: null,
  type: null,
  groupId: [],
  imgUrl: '',
});

const DEBOUNCE_TIME = 300;
const onChangeInput = debounce(() => {
  onSearch();
}, DEBOUNCE_TIME);
const onSearch = (isResetPage = true) => {
  table.value.fetchData(isResetPage).then();
  let query = {
    ...route.query,
    searchName: trim(formState.name),
    searchType: formState.type,
    searchGroupId: formState.groupId.join(','),
    searchImage: formState.imgUrl,
  };
  if (isResetPage) {
    query.page = 1;
  }
  router.push({
    query: query,
  });
};
const refresh = () => {
  resetForm();
  table.value.resetAndFetch(true);
  router.push({
    query: {},
  });
};

const resetForm = () => {
  formState.name = '';
  formState.type = null;
  formState.groupId = [];
  formState.imgUrl = '';
};

const showConfirmCustom = ref(null);
const onDelete = async (rows) => {
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    dispatch('objManagement/deleteIdentityObj', rows).then(
      setTimeout(() => {
        onSearch(false);
      }, 1000)
    );
  }
};
const router = useRouter();
const onEdit = (record) => {
  router.push({
    name: '/io-management/object-management/edit',
    query: {
      id: record.id,
      type: record.type,
      mode: hasPermEdit(perms.value, 'object')
        ? FORM_MODE.UPDATE
        : FORM_MODE.VIEW,
      page: table.value.page,
      ...route.query,
    },
  });
};
const onView = (record) => {
  router.push({
    name: '/io-management/object-management/edit',
    query: {
      id: record.id,
      type: record.type,
      mode: FORM_MODE.VIEW,
      page: table.value.page,
      ...route.query,
    },
  });
};
const onCreate = () => {
  router.push({
    path: '/io-management/object-management/create',
  });
};
const viewDetailGroup = (item) => {
  router.push({
    path: '/io-management/group-management',
    query: { idGroup: item, page: table.value.page },
  });
};
</script>

<style lang="scss">
.list-io .img-custom img {
  border-radius: 8px;
}
.list-io .img-custom img:hover {
  transition: 0.2s;
  transform: scale(1.2);
}
.list-io .tag:hover {
  transition: 0.2s;
  transform: scale(1.1);
}
.list-io .icon-expand-drawer {
  background-image: url('../../static/img/icon/open-drawer.svg');
  background-size: cover;
  height: 67px;
  width: 19px;
}
.list-io .icon-expand-drawer:hover {
  background-image: url('../../static/img/icon/open-drawer-active.svg');
}
.list-io .ant-upload {
  height: 100%;
  font-size: 16px;
  line-height: 1;
}
.list-io .ant-upload button {
  height: 100%;
}
.popup-table-user .ant-popover-arrow-content {
  background-color: #1f1f1f !important;
}
.popup-table-user .ant-popover-inner {
  box-shadow: 0 4px 84px 0 rgba(255, 255, 255, 0.014);
  --tw-bg-opacity: 1;
  background-color: rgba(17, 17, 29, var(--tw-bg-opacity));
  border-radius: 4px;
}
</style>
